import { localCartService } from './local-cart';
import { webhookCartService } from './webhook-cart';
import { iframePostMessageCartService } from './iframePostMessage-cart';

const getDescriptions = ({
  categoriesToShow,
  categorySettings,
  userSelections,
  selectedFit,
  selectedBody,
  monogramSelections,
  monogramInitials
}) => {
  const descriptions = [
    ['Sized For', selectedBody.firstName + ' ' + selectedBody.lastName],
    ['Fit', selectedFit.answer],
    ...categoriesToShow.reduce((acc, k) => {
      const v = categorySettings[k];
      if (v && v.selection_panel_layout != 'monogram') {
        acc.push([v.display_name, userSelections[k].name]);
      }
      return acc;
    }, [])
  ];

  if (Object.keys(userSelections).includes('monogram_initials')) {
    const monogramText =
      !userSelections['monogram_initials'] ? 'No' : (
        `(${[
          monogramInitials.toUpperCase(),
          ...Object.values(monogramSelections).map((v) => v.name)
        ].join(', ')})`
      );

    descriptions.push(['Monogram', monogramText]);
  }

  return descriptions;
};

const getDescription = (descriptions) => {
  const { monogram, ...descriptionTexts } = descriptions;

  var monogramText;
  if (!monogram) {
    monogramText = '';
  } else if (monogram === 'No') {
    monogramText = `, with no monogram`;
  } else {
    monogramText = `, with monogram ${monogram}`;
  }

  return (
    Object.entries(descriptionTexts)
      .map((category, selection) => `${selection} ${category}`)
      .join(', ') + monogramText
  );
};

const cartServices = {
  local: localCartService,
  webhook: webhookCartService,
  iframePostMessage: iframePostMessageCartService
};

export const useCartServices = ({
  productKey,
  monogramInitials,
  lineItemId,
  authenticityToken,
  quantity,
  itemCost,
  userSelections,
  selectedFit,
  selectedBody,
  monogramSelections,
  productExternalId,
  categoriesToShow,
  categorySettings,
  cartSettings,
  isItemClone
}) => {
  const { cart, cartUrl, cartApi } = cartSettings;

  const handleSubmit = async () => {
    try {
      const selections = {
        ...Object.keys(userSelections).reduce((acc, category) => {
          acc[category] = userSelections[category]?.id;
          return acc;
        }, {}),
        product_key: productKey,
        fit: selectedFit.gpp_shape_code,
        monogram_initials_text: monogramInitials || ''
      };

      const descriptions = getDescriptions({
        categoriesToShow,
        categorySettings,
        userSelections,
        selectedFit,
        selectedBody,
        monogramSelections,
        monogramInitials
      });
      const description = getDescription(descriptions);

      if (lineItemId && !isItemClone) {
        await cartServices[cart].updateInCart({
          cartApi,
          lineItemId,
          selections,
          itemCost,
          quantity,
          descriptions,
          description,
          cartUrl,
          productExternalId,
          authenticityToken
        });
      } else {
        const requestBody = { ...selections, selected_body: selectedBody };
        if (isItemClone) {
          // Find source line item from the URL
          let matched = false;
          let matchedSlug = '';

          window.location.pathname.split('/').some((slug) => {
            if (slug === 'clone') {
              matched = true;
              return;
            }

            if (matched) {
              matchedSlug = slug;
              return true;
            }
          });

          if (matchedSlug) {
            requestBody['source_line_item'] = matchedSlug;
          }
        }

        const res = await window.fetch(`/configured_items`, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': authenticityToken
          },
          body: JSON.stringify(requestBody),
          method: 'POST'
        });
        const response = await res.json();
        const { configured_item } = response;

        const preview = `${window.location.origin}/configured_items/${configured_item.id}/preview`;

        await cartServices[cart].addToCart({
          cartApi,
          configured_item,
          itemCost,
          quantity,
          descriptions,
          description,
          preview,
          cartUrl,
          productExternalId,
          authenticityToken,
          selectedBody
        });
      }
    } catch (e) {
      console.log('ERROR', e);
      alert(`Something went wrong.  Your item was not added to the basket.`);
    }
  };

  return handleSubmit;
};
