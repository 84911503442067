import React from 'react';
import cx from 'classnames';
import MonogramSelectedIcon from 'images/monogram-selected-check.png';

export default function SubSelectionPalletLocation({
  options,
  selectedAttribute,
  setSelectedAttribute
}) {
  return (
    <div className="monogram-placement">
      <div className="monogram-selection-header">Choose Placement</div>
      <div className="row no-gutters fc-monogram-pallet-row-placement">
        {options?.map((placement) => {
          return (
            <SubSelectionPalletLocationItem
              key={placement.id}
              attr={placement}
              isSelectedAttribute={placement === selectedAttribute}
              setSelectedPlacement={setSelectedAttribute}
            />
          );
        })}
      </div>
    </div>
  );
}

export function SubSelectionPalletLocationItem({
  attr,
  setSelectedPlacement,
  isSelectedAttribute
}) {
  const [isActive, setIsActive] = React.useState(null);
  const containerClasses = cx(
    'monogram-color-blocks',
    (isActive || isSelectedAttribute) && 'monogram-color-blocks-active'
  );

  const handleColorSelection = () => {
    if (!isSelectedAttribute) {
      setSelectedPlacement(attr);
    }
  };

  return (
    <span className="custom-col-5 fc-monogram-pallet">
      <span
        className="fc-subselection-pallet-image-container"
        onMouseLeave={() => setIsActive(false)}
        onMouseEnter={() => setIsActive(true)}
        onClick={handleColorSelection}
      >
        {isSelectedAttribute && (
          <span className="fc-selected-sign fc-monogram-selected-sign">
            <img
              src={MonogramSelectedIcon}
              className="monogram-icon"
              alt="Selected"
            />
          </span>
        )}
        <div className={containerClasses}>
          <div className="fc-monogram-pallet-image">
            <span>{attr.name}</span>
          </div>
        </div>
      </span>
    </span>
  );
}
