import React from "react";
import ButtonholeColorItem from "./ButtonholeColorItem";

export default function ButtonholeColors({
 options,
 selectedAttribute,
 setSelectedAttribute
}) {

  return (
    <div className="monogram-color">
      <div className="monogram-selection-header">Choose Color</div>

      <div className="row no-gutters fc-monogram-pallet-row-color">
        {options?.map((color) => {
          return (
            <ButtonholeColorItem
              key={color.id}
              attr={color}
              isSelectedAttribute={color === selectedAttribute}
              setSelectedColor={setSelectedAttribute}
            />
          );
        })}
      </div>
    </div>
  );
}
