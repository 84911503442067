import React from 'react';
import PreviewImage from './PreviewImage';
import MonogramPreview from './MonogramPreview';

export default function AttributesImagePreview({
  productKey,
  userSelections,
  categories,
  monogramCategories,
  remoteImagesPath,
  resizedRemoteImagesPath,
  resizedImageExtension,
  categoriesWithoutPreview,
  monogramInitials,
  monogramError,
  selectedMonogramStyleSpecs,
  defaultMonogramColor,
  onImageLoadError
}) {
  // Images are fetched according to the convention `attribute_overlay_<fabric code from GPP>__<GPP code from attribute>`
  const fabricSelection =
    userSelections['fabric'] && userSelections['fabric'].gpp_code;

  return (
    <div className="fc-preview-container">
      <div className="fc-attribute-preview-container">
        {categories.map((category) => {
          if (categoriesWithoutPreview.includes(category)) {
            return;
          }
          const imageCodeSuffix =
            userSelections[category] ? `__${category}` : `default_${category}`;
          let imageCode;
          if (monogramCategories.includes(category)) {
            return null;
          } else if (category === 'pocket' && !userSelections[category]) {
            return null;
          } else if (category === 'fabric') {
            imageCode = `${fabricSelection}__${productKey}${imageCodeSuffix}`;
          } else {
            const gppCode =
              userSelections[category] ? userSelections[category].gpp_code : '';
            imageCode = `${fabricSelection}__${productKey}__${gppCode}${imageCodeSuffix}`;
          }

          return (
            <PreviewImage
              key={category}
              src={imageCode}
              remoteImagesPath={remoteImagesPath}
              resizedRemoteImagesPath={resizedRemoteImagesPath}
              resizedImageExtension={resizedImageExtension}
              imgClass="fc-attribute-preview-image"
            />
          );
        })}

        {userSelections.monogram_initials != null ?
          <svg
            className="fc-attribute-preview-image"
            height="750"
            width="750"
            viewBox="0 0 200 200"
          >
            <svg x="20" height="50" width="50" y="60">
              <MonogramPreview
                userSelections={userSelections}
                monogramInitials={monogramInitials}
                monogramError={monogramError}
                selectedMonogramStyleSpecs={selectedMonogramStyleSpecs}
                defaultMonogramColor={defaultMonogramColor}
              />
            </svg>
          </svg>
        : null}
      </div>
    </div>
  );
}
