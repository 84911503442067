import { useState, useEffect } from 'react';

const useImageLoader = (imageUrl, DefaultImage) => {
  const [loadedImageUrl, setLoadedImageUrl] = useState('');

  useEffect(() => {
    const img = new Image();
    img.onload = () => setLoadedImageUrl(imageUrl);
    img.onerror = () => setLoadedImageUrl(DefaultImage);
    img.src = imageUrl;
  }, [imageUrl]);

  return loadedImageUrl;
};

export default useImageLoader;
