import React, { useEffect } from 'react';
import NoImageIcon from 'images/no-image-icon.svg';

const errorStates = {
  RESIZED_IMAGE_ERROR: 'RESIZED_IMAGE_ERROR',
  ORIGINAL_IMAGE_ERROR: 'ORIGINAL_IMAGE_ERROR'
};

export default function PreviewImage({
 src,
 remoteImagesPath,
 resizedRemoteImagesPath,
 resizedImageExtension,
 imgClass
}) {
  const [onError, setOnError] = React.useState(null);
  const imageExtension = '.png';
  const imagePath = `${remoteImagesPath}/${src}${imageExtension}`;
  const resizedImagePath = `${resizedRemoteImagesPath}/${src}.${
    resizedImageExtension || imageExtension
  }`;

  useEffect(() => {
    setOnError(null)
  }, [imagePath]);

  if (onError === errorStates.ORIGINAL_IMAGE_ERROR) {
    return (
      <img className={'fc-attribute-preview-no-image'} src={NoImageIcon} alt={'no-image-icon'} />
    );
  } else if (onError === errorStates.RESIZED_IMAGE_ERROR) {
    return (
      <img
        src={imagePath}
        alt="Laydown image preview"
        className={imgClass}
        onError={() => setOnError(errorStates.ORIGINAL_IMAGE_ERROR)}
      />
    );
  } else {
    return (
      <img
        src={resizedImagePath}
        alt="Laydown image preview"
        className={imgClass}
        onError={() => setOnError(errorStates.RESIZED_IMAGE_ERROR)}
      />
    );
  }
}
