export const addToCartResultHandling = (
  data,
  cartUrl,
  skipSingleItemRestriction
) => {
  if (
    !skipSingleItemRestriction &&
    data.error === 'single_item_restriction_fit_confirm_pending'
  ) {
    alert(
      `It looks like your first shirt has not been fit confirmed yet.\n\nWe only allow one product per category for first time orders.This ensures you get exactly the right fit and that all of your expectations are met.Your next order will allow the purchase of multiple items.`
    );
  } else if (data.error === 'user_not_signed_in') {
    $('#ScanRequiredModal').modal('show');
  } else {
    window.open(cartUrl, '_top');
  }
};
