export const CONTRAST_STITCHING_NO_OPTION_GPP_CODE = {
  contrast_stitching_color: ['VC26000', 'VC26440'],
  contrast_stitching_location: ['VC25999', 'VC26439']
};

export const CONTRAST_STITCHING_CATEGORIES_SET = new Set(
  Object.keys(CONTRAST_STITCHING_NO_OPTION_GPP_CODE)
);

export const CONTRAST_STITCHING_CATEGORIES_ARRAY = Array.from(
  CONTRAST_STITCHING_CATEGORIES_SET
);
