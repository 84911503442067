import React from 'react';
import ArrowLeft from 'images/arrow-back.svg';

export default function BackToConfigurator({
  setCurrentScreen,
  currencySymbol
}) {
  const customizer = currencySymbol === '$' ? 'Customizer' : 'Customiser';

  return (
    <div onClick={() => setCurrentScreen('main')} className="fc-back-button">
      <img src={ArrowLeft} alt="Arrow pointing left" />
      <span className="fc-back-to-configurator-title">
        Back to {customizer}
      </span>
    </div>
  );
}
