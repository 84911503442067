import React from 'react';
import ArrowRight from 'images/arrow-right.svg';
import cx from 'classnames';

export default function FabricSelectionRow({
  fabric,
  setCurrentScreen,
  canOpen
}) {
  const classNames = cx(
    'fc-option-dropdown-arrow-collapsed',
    !canOpen && 'fc-reduced-opacity'
  );
  const handleClick = () => {
    if (canOpen) {
      setCurrentScreen('fabric');
    }
  };

  return (
    <div className="row no-gutters fc-option" onClick={handleClick}>
      <div className="col fc-option-title">Fabric</div>
      {fabric ? (
        <FabricSelection fabric={fabric} />
      ) : (
        <div className="fc-option-empty-selection-title">Make a selection</div>
      )}
      <div className="col-1 fc-option-dropdown-arrow">
        <div className={classNames}>
          <img src={ArrowRight} alt="Arrow pointing right" />
        </div>
      </div>
    </div>
  );
}

export function FabricSelection({ fabric }) {
  return <div className="col fc-option-selection-title">{fabric.name}</div>;
}
