import React from 'react';
import cx from 'classnames';
import MonogramSelectedIcon from 'images/monogram-selected-check.png';

export default function MonogramPalletStyle({
  options,
  selectedAttribute,
  setSelectedAttribute,
  monogramStylesSpecs
}) {
  return (
    <div className="monogram-style">
      <div className="monogram-selection-header">Choose Style</div>
      <div className="row no-gutters fc-monogram-pallet-row">
        {options?.map((style) => {
          return (
            <MonogramPalletStyleItem
              key={style.id}
              attr={style}
              isSelectedAttribute={style === selectedAttribute}
              setSelectedStyle={setSelectedAttribute}
              monogramStyleSpecs={
                monogramStylesSpecs[style.gpp_code] ||
                monogramStylesSpecs.default
              }
            />
          );
        })}
      </div>
    </div>
  );
}

export function MonogramPalletStyleItem({
  attr,
  setSelectedStyle,
  isSelectedAttribute,
  monogramStyleSpecs
}) {
  const [isActive, setIsActive] = React.useState(null);

  const containerClasses = cx(
    'monogram-color-blocks',
    (isActive || isSelectedAttribute) && 'monogram-color-blocks-active'
  );

  const handleColorSelection = () => {
    if (!isSelectedAttribute) {
      setSelectedStyle(attr);
    }
  };

  const monogramPreviewStyle = monogramStyleSpecs.monogramPreviewStyle;

  return (
    <span className="col-lg-4 col-sm-6 fc-monogram-pallet">
      <span
        className="fc-monogram-pallet-image-container"
        onMouseLeave={() => setIsActive(false)}
        onMouseEnter={() => setIsActive(true)}
        onClick={handleColorSelection}
      >
        {isSelectedAttribute && (
          <span className="fc-selected-sign fc-monogram-selected-sign">
            <img
              src={MonogramSelectedIcon}
              className="monogram-icon"
              alt="Selected"
            />
          </span>
        )}
        <div className={containerClasses}>
          <div className={`fc-monogram-pallet-style ${monogramPreviewStyle}`}>
            {monogramStyleSpecs.previewInitials
              .split('')
              ?.map((val, index) => (
                <span
                  key={index}
                  className={`monogram-style-text ${monogramPreviewStyle}`}
                  style={monogramStyleSpecs.style}
                >
                  {val}
                </span>
              ))}
          </div>
        </div>
      </span>
      <div className="fc-fabric-title fc-monogram-title">{attr.name}</div>
    </span>
  );
}


