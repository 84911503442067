import React, { useEffect, useRef, useState } from 'react';
import MonogramOffset from './monogramStyles/MonogramOffset';
import MonogramDiamond from './monogramStyles/MonogramDiamond';
import MonogramDefault from './monogramStyles/MonogramDefault';

export default function MonogramPreview({
  userSelections,
  monogramInitials,
  monogramError,
  selectedMonogramStyleSpecs,
  defaultMonogramColor
}) {
  const [key, setKey] = useState(0);

  useEffect(() => {
    // Incrementing the key whenever monogramInitials or userSelections.monogram_style changes
    setKey((prevKey) => prevKey + 1);
  }, [
    monogramInitials,
    userSelections.monogram_style,
    userSelections.monogram_color
  ]);

  return (
    <svg key={key} className="monogram-preview" viewBox="0 0 200 200">
      <defs>
        <pattern
          id="pattern8"
          width="200"
          height="200"
          patternUnits="userSpaceOnUse"
          preserveAspectRatio="xMidYMid slice"
        >
          <image
            width="200"
            height="200"
            preserveAspectRatio="xMidYMid slice"
            href={userSelections['fabric']?.images?.display}
          />
        </pattern>
      </defs>
      <circle
        cx="100"
        cy="100"
        r="99.5"
        fill="url(#pattern8)"
        stroke="#000"
        strokeWidth="1"
      />
      <MonogramContent
        userSelections={userSelections}
        monogramInitials={monogramInitials}
        monogramError={monogramError}
        selectedMonogramStyleSpecs={selectedMonogramStyleSpecs}
        defaultMonogramColor={defaultMonogramColor}
      />
    </svg>
  );
}

function MonogramContent({
  userSelections,
  monogramInitials,
  selectedMonogramStyleSpecs,
  defaultMonogramColor
}) {
  const svgRef = useRef();

  useEffect(() => {
    const textElements = svgRef.current.querySelectorAll('text');
    if (textElements.length > 0) {
      const bbox = Array.from(textElements).reduce(
        (acc, text) => text.getBBox(),
        textElements[0].getBBox()
      );

      const margin = selectedMonogramStyleSpecs.margin;
      svgRef.current.setAttribute(
        'viewBox',
        `${bbox.x - margin} ${bbox.y} ${bbox.width + 2 * margin} ${bbox.height}`
      );
    }
  }, [monogramInitials, userSelections.monogram_style]);

  const MonogramComponents = {
    'monogram-style-text': MonogramDefault,
    'script-initial-code': MonogramOffset,
    'diamond-monogram': MonogramDiamond,
  };

  const selectedMonogramStyle = selectedMonogramStyleSpecs.monogramPreviewStyle;
  const MonogramComponent = MonogramComponents[selectedMonogramStyle];

  return (
    <svg ref={svgRef}>
      {MonogramComponent && (
        <MonogramComponent
          monogramInitials={monogramInitials}
          style={selectedMonogramStyleSpecs.style}
          fontColor={(userSelections.monogram_color || defaultMonogramColor)}
        />
      )}
    </svg>
  );
}
