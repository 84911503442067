// MonogramOffset.js
import React from 'react';

const MonogramOffset = ({ monogramInitials, style, fontColor }) => {
  const monogramChars = monogramInitials?.slice(0, 2); // Take only the first two characters
  return monogramChars?.split('').map((initial, index) => (
    <text
      key={index}
      style={{
        ...style,
        fontWeight: 700,
        fontSize: '37px',
        transform: index === 0 ? 'translate(-18px, -5px)' : 'translate(9px, 11px)',
        fill: fontColor.font_family,
        letterSpacing: '5px'
      }}
    >
      {initial}
    </text>
  ));
};

export default MonogramOffset;