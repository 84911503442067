// MonogramDefault.js
import React from 'react';

const MonogramDefault = ({ monogramInitials, style, fontColor }) => (
  <text
    style={{
      ...style,
      fontSize: '50px',
      fill: fontColor.font_family,
      letterSpacing: '5px'
    }}
  >
    {monogramInitials}
  </text>
);

export default MonogramDefault;
