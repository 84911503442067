import { useEffect } from 'react';

/**
 * Intended to be used in iframes with ESS. This forwards local storage get/set post messages
 * to its parent window.
 */
export const usePostedMessageForwarder = (iframeUrl, cartApi) => {
  useEffect(() => {
    const handleMessage = (event) => {
      if (!event.data.action) return;
      // cartApi
      if (event.origin === iframeUrl) {
        if (event.data.action === 'storeData' && event.data.body.key) {
          window.parent.postMessage(event.data, cartApi);
        } else if (
          event.data.action === 'retrieveData' &&
          event.data.body.key
        ) {
          window.parent.postMessage(event.data, cartApi);
        }
      } else if (event.origin === cartApi) {
        if (event.data.action === 'returnRetrievedData') {
          const iframeEl = document.querySelector('.fc-iframe-shopify-ess');
          if (!iframeEl) return;

          iframeEl.contentWindow.postMessage(event.data, iframeUrl);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, []);
};
