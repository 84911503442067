import React, { useState, useMemo, useEffect } from 'react';

export default function ModelImagePreview({
  productKey,
  selectedFabric,
  remoteImagesPath,
  brandCode,
  showCustomPreviewImage
}) {
  const [imageIndex, setImageIndex] = useState(0);
  // Array of images sources. We'll start with index 0 and fallback to the next one if
  // we are unable to load current image
  const imageSources = useMemo(() => {
    let sourcesArray = [];

    if (selectedFabric) {
      sourcesArray.push(
        `${remoteImagesPath}/${selectedFabric.gpp_code}__${productKey}__model.jpg`
      );
    }

    if (showCustomPreviewImage) {
      sourcesArray.push(
        `${remoteImagesPath}/default__${brandCode}__${productKey}__model.jpg`
      );
    }

    sourcesArray.push(`${remoteImagesPath}/default__${productKey}__model.jpg`);

    return sourcesArray;
  }, [productKey, selectedFabric, remoteImagesPath]);

  const handleOnError = () => {
    setImageIndex((imgIdx) =>
      imgIdx < imageSources.length - 1 ? imgIdx + 1 : imgIdx
    );
  };

  useEffect(() => {
    // reset image index when the imageSources array gets updated
    setImageIndex(0);
  }, [imageSources]);

  return (
    <div className="fc-preview-container">
      <img
        src={imageSources[imageIndex]}
        alt={'Product preview'}
        className="fc-preview-lifestyle fc-preview-image"
        onError={handleOnError}
      />
    </div>
  );
}
