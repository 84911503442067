import React from 'react';

export default function ConfiguratorHeader({ productName, currencySymbol }) {
  const title = currencySymbol === '$' ? 'Customizer' : 'Customiser';

  return (
    <div className="fc-options-headings">
      <div className="fc-options-title">{title}</div>
      <div className="fc-options-description">
        <span>
          Choose from our carefully curated selection of fabrics and then
          customize the {productName.toLowerCase()} to your preference.{' '}
        </span>
        <span className="fc-options-description-detail">
          The {productName.toLowerCase()} will be cut to your shape.
        </span>
      </div>
    </div>
  );
}
