import React from 'react';
import NextStepButton from '../NextStepButton';
import BackToConfigurator from '../BackToConfigurator';
import cx from 'classnames';
import ButtonholeColors from "./ButtonholeColors";

const CONTRAST_BUTTONHOLE = 'contrast_buttonhole'

export default function ButtonholeSelectionPanel({
 productName,
 currencySymbol,
 nextAttribute,
 nextAttributeName,
 previousAttribute,
 setCurrentScreen,
 isDisabled,
 selectableOptionsTable,
 setSelectedAttributeFunctions,
 userSelections,
 canAddToBasket
}) {

  const defaultOption = selectableOptionsTable[CONTRAST_BUTTONHOLE]?.find((option) => option.font_family === null || option.name === "NO");

  const enabled = JSON.stringify(userSelections[CONTRAST_BUTTONHOLE]) !== JSON.stringify(defaultOption) && userSelections[CONTRAST_BUTTONHOLE] !== null;

  const toggleButtonholes = () => {
    if (enabled) {
      setSelectedAttributeFunctions[CONTRAST_BUTTONHOLE](defaultOption);
    } else {
      const options = selectableOptionsTable[CONTRAST_BUTTONHOLE];
      setSelectedAttributeFunctions[CONTRAST_BUTTONHOLE](options?.[0]);
    }
  };

  React.useEffect(() => {
    if(userSelections[CONTRAST_BUTTONHOLE] === null) {
      setSelectedAttributeFunctions[CONTRAST_BUTTONHOLE](defaultOption);
    }
  }, [userSelections]);

  const containerClasses = cx(
    'row no-gutters fc-fabric-selection-pallet',
    enabled ?
      'monogram-selection-pallet-scrollbar'
      : 'monogram-selection-w-pallet-scrollbar'
  );

  return (
    <div className="fc-selection-panel fc-custom-selection-panel">
      <div className="d-flex flex-column">
        <div className="fc-selection-panel-content">
          <div className="row fc-back-to-configurator-row">
            <BackToConfigurator
              setCurrentScreen={setCurrentScreen}
              currencySymbol={currencySymbol}
            />
          </div>
          <div className="row fc-selection-panel-options fc-custom-selection-panel-options monogram">
            <div className="fc-selection-panel-title-row">
              <div className="col fc-selection-panel-title">
                Select Contrast Buttonholes
              </div>
            </div>
          </div>

          <div className={containerClasses}>
            <div className="row no-gutters fc-fabric-pallet-row monogram-panel">
              <label
                className="monogram-checkbox"
                style={
                  !enabled ?
                    { backgroundColor: 'white' }
                    : { backgroundColor: '#ececec' }
                }
              >
                <input
                  type="checkbox"
                  defaultChecked={enabled}
                  onChange={toggleButtonholes}
                />
                <div className="monogram-checkbox-check"></div>
                <span className="fc-selection-panel-title monogram-panel-title">
                  Yes, Contrast Buttonholes
                </span>
              </label>

              {enabled ? (
                <div className="row no-gutters fc-fabric-pallet-row">
                  <ButtonholeColors
                    options={selectableOptionsTable[CONTRAST_BUTTONHOLE]}
                    selectedAttribute={userSelections[CONTRAST_BUTTONHOLE]}
                    setSelectedAttribute={setSelectedAttributeFunctions[CONTRAST_BUTTONHOLE]}
                  />
                </div>
              ) : (
                <p className="monogram-text">
                  Contrast Buttonholes are another way to add a special detail.
                  <br />
                  Choose from several colors.
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="d-none d-md-block">
          <NextStepButton
            nextAttribute={nextAttribute}
            nextAttributeName={nextAttributeName}
            previousAttribute={previousAttribute}
            isDisabled={isDisabled}
            setCurrentScreen={setCurrentScreen}
            productName={productName}
            canAddToBasket={canAddToBasket}
          />
        </div>
      </div>
    </div>
  );
}
