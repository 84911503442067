import { addToCartResultHandling } from './result-handling';

const addToCart = async ({
  configured_item,
  quantity,
  authenticityToken,
  cartUrl
}) => {
  const queryString = new URLSearchParams({
    configured_item_id: configured_item.id,
    quantity: quantity
  }).toString();

  const res = await window.fetch(`/line_items?${queryString}`, {
    headers: {
      'X-CSRF-Token': authenticityToken
    },
    method: 'POST'
  });
  const data = await res.json();

  addToCartResultHandling(data, cartUrl, true);
};

const updateInCart = async ({
  lineItemId,
  selections,
  quantity,
  authenticityToken,
  cartUrl
}) => {
  const queryString = new URLSearchParams({
    ...selections,
    quantity: quantity
  }).toString();

  const res = await window.fetch(`/line_items/${lineItemId}?${queryString}`, {
    headers: {
      'X-CSRF-Token': authenticityToken
    },
    method: 'PUT'
  });

  const data = await res.json();
  addToCartResultHandling(data, cartUrl, true);
  return;
};

export const localCartService = {
  addToCart,
  updateInCart
};
