import React from 'react';

const MonogramDiamond = ({ monogramInitials, style, fontColor }) => (
  <>
    {monogramInitials.length === 1 ? (
      // Render option for one character
      <text
        style={{
          ...style,
          fontSize: '27px',
          fill: fontColor.font_family,
          letterSpacing: '0px'
        }}
      >
        {monogramInitials}
      </text>
    ) : monogramInitials.length === 2 ? (
      // Render option for two characters
      <>
        <text
          style={{
            fontFamily: 'Diamond Mono Left, sans-serif',
            fontWeight: 200,
            fontSize: '30px',
            fill: fontColor.font_family,
            letterSpacing: '1px',
            transform: 'translate(-4px, 0)'
          }}
        >
          {monogramInitials[0]}
        </text>
        <text
          style={{
            fontFamily: 'Diamond Mono Right, sans-serif',
            fontWeight: 200,
            fontSize: '30px',
            fill: fontColor.font_family,
            letterSpacing: '1px',
            transform: 'translate(4px, 0)'
          }}
        >
          {monogramInitials[1]}
        </text>
      </>
    ) : (
      // Render option for three characters
      <>
        <polygon
          points="-2,0 0,2 2,0"
          style={{
            fill: fontColor.font_family,
            transform: 'translate(-5.3px, -4px) rotate(90deg)'
          }}
        />
        <text
          style={{
            fontFamily: 'Diamond Mono Left, sans-serif',
            fontWeight: 200,
            fontSize: '15px',
            fill: fontColor.font_family,
            letterSpacing: '0px',
            transform: 'translate(-5.5px, 1.5px)'
          }}
        >
          {monogramInitials[0]}
        </text>
        <text
          style={{
            ...style,
            fontSize: '20px',
            fill: fontColor.font_family,
            letterSpacing: '1px',
            transform: 'translate(-1.5px, 3px)'
          }}
        >
          {monogramInitials[1]}
        </text>
        <text
          style={{
            fontFamily: 'Diamond Mono Right, sans-serif',
            fontWeight: 200,
            fontSize: '15px',
            fill: fontColor.font_family,
            letterSpacing: '0px',
            transform: 'translate(4.5px, 1.3px)'
          }}
        >
          {monogramInitials[2]}
        </text>
        <polygon
          points="-2,0 0,2 2,0"
          style={{
            fill: fontColor.font_family,
            transform: 'translate(9px, -4px) rotate(-90deg)'
          }}
        />
      </>
    )}
  </>
);

export default MonogramDiamond;
