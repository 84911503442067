import React from 'react';
import cx from 'classnames';

export default function NextStepButton({
  nextAttribute,
  nextAttributeName,
  previousAttribute,
  isDisabled,
  productName,
  setCurrentScreen,
  canAddToBasket
}) {
  const nextScreen = nextAttribute || 'main';
  const previousScreen = previousAttribute || 'main';

  const changeScreen = (newScreen) => {
    setCurrentScreen(newScreen);
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 100);
  };

  const handleNextClick = () => {
    changeScreen(nextScreen);
  };

  const handlePreviousClick = () => {
    changeScreen(previousScreen);
  };

  const enableShortcutButton = nextAttribute && canAddToBasket;

  return (
    <div className="d-flex flex-row fc-configurator-button-container">
      <button
        className="btn fc-btn-configurator-previous btn-default"
        onClick={handlePreviousClick}
      >
        Previous
      </button>

      <button
        className={cx('btn btn-default', {
          'fc-btn-configurator-previous': enableShortcutButton,
          'fc-btn-configurator-step': !enableShortcutButton
        })}
        onClick={handleNextClick}
        disabled={isDisabled}
      >
        {nextAttribute ?
          `Continue to ${nextAttributeName || nextAttribute}`
        : `Finalize my ${productName}`}
      </button>

      {enableShortcutButton && (
        <button
          className="btn btn-default fc-btn-configurator-step"
          onClick={() => changeScreen('main')}
          disabled={isDisabled}
        >
          {`Finalize my ${productName}`}
        </button>
      )}
    </div>
  );
}
