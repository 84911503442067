import React from 'react';
import cx from 'classnames';
import MonogramSelectedIcon from 'images/monogram-selected-check.png';

export default function SubSelectionPalletColor({
  options,
  selectedAttribute,
  setSelectedAttribute
}) {
  return (
    <div className="monogram-color">
      <div className="monogram-selection-header">Choose Color</div>

      <div className="row no-gutters fc-monogram-pallet-row-color">
        {options?.map((color) => {
          return (
            <SubSelectionPalletColorItem
              key={color.id}
              attr={color}
              isSelectedAttribute={color === selectedAttribute}
              setSelectedColor={setSelectedAttribute}
            />
          );
        })}
      </div>
    </div>
  );
}

export function SubSelectionPalletColorItem({
  attr,
  isSelectedAttribute,
  setSelectedColor
}) {
  const [isActive, setIsActive] = React.useState(null);

  const containerClasses = cx(
    'monogram-color-blocks',
    (isActive || isSelectedAttribute) && 'monogram-color-blocks-active'
  );

  const handleColorSelection = () => {
    if (!isSelectedAttribute) {
      setSelectedColor(attr);
    }
  };

  const monogramBlock = cx('col-lg-4', 'col-sm-4', 'fc-monogram-pallet');

  const styles =
    attr.font_family !== '#FFFFFF' ?
      {
        backgroundColor: attr.font_family
      }
    : {
        backgroundColor: attr.font_family,
        border: '1px solid #d0d0d0',
        color: 'black'
      };

  return (
    <span className={monogramBlock}>
      <span
        className="fc-monogram-pallet-image-container"
        onMouseLeave={() => setIsActive(false)}
        onMouseEnter={() => setIsActive(true)}
        onClick={handleColorSelection}
      >
        {isSelectedAttribute && (
          <span className="fc-selected-sign fc-color-selected-sign">
            <img
              src={MonogramSelectedIcon}
              className="monogram-icon"
              alt="Selected"
            />
          </span>
        )}
        <div className={containerClasses}>
          <div className="fc-monogram-pallet-color" style={styles}>
            <span>{attr.name}</span>
          </div>
        </div>
      </span>
    </span>
  );
}
