import React from 'react';
import FabricInfoIcon from 'images/fabric-info-icon.svg';

export default function FabricDescriptionModal({ fabric, currencySymbol }) {
  const rawDescription = fabric.text.description;
  let description;
  if (rawDescription) {
    const lowerCaseDescription = fabric.text.description
      .split('. ')
      .map(
        (sentence) =>
          sentence[0] &&
          sentence[0].toUpperCase() + sentence.substring(1).toLowerCase()
      );
    description = lowerCaseDescription && lowerCaseDescription.join('. ');
  }

  return (
    <div
      className="modal fade"
      id={`FabricDescriptionModal-${fabric.id}`}
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button
              type="button"
              className="fc-close-information-button"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
          </div>
          <div className="modal-body fc-fabric-information-modal">
            <div className="row fc-fabric-modal-title">
              <span className="col-1 m-auto">
                <img
                  src={FabricInfoIcon}
                  className="fc-information-icon"
                  alt="Information icon"
                />
              </span>
              <span className="col-11 m-auto">{fabric.name}</span>
            </div>
            <p className="fc-fabric-information-description">{description}</p>
            <p className="fc-fabric-information-description pt-0">
              {currencySymbol}
              {Math.round(fabric.price)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
