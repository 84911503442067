import { addToCartResultHandling } from './result-handling';

const addToCart = async ({
  cartApi,
  configured_item,
  itemCost,
  quantity,
  description,
  preview,
  cartUrl
}) => {
  const res = await window.fetch(cartApi, {
    method: 'POST',
    body: JSON.stringify({
      configured_item: configured_item.id,
      price: itemCost * quantity,
      description,
      preview
    })
  });

  const data = await res.json();
  addToCartResultHandling(data, cartUrl);
};

export const webhookCartService = {
  addToCart
};
