const addToCart = async ({
  cartApi,
  configured_item,
  quantity,
  description,
  descriptions,
  preview,
  selectedBody
}) => {
  const [skuProductId, sku] = configured_item.sku.split(':');
  window.parent.postMessage(
    {
      action: 'addCustomCommerceItem',
      body: {
        productId: skuProductId,
        catalogRefId: sku,
        quantity,
        configuredItemId: configured_item.id,
        display: {
          configurations: Object.fromEntries(descriptions),
          thumbnail_img_url: preview
        },
        description,
        hasScan: !!selectedBody['scanId']
      }
    },
    cartApi
  );
};

const updateInCart = async ({
  configuredItemIds,
  selectedBody,
  authenticityToken,
  cartApi,
  descriptions
}) => {
  const res = await window.fetch(`/configured_items/bulk_update`, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken
    },
    body: JSON.stringify({
      configured_item_ids: configuredItemIds,
      selected_body: selectedBody
    }),
    method: 'PATCH'
  });

  if (res.ok) {
    window.parent.postMessage(
      {
        action: 'configuredItemIsComplete',
        body: {
          configuredItemIds,
          display: {
            configurations: Object.fromEntries(descriptions)
          }
        }
      },
      cartApi
    );
  }
};

export const iframePostMessageCartService = {
  addToCart,
  updateInCart
};
