import React from 'react';
import ArrowRight from 'images/arrow-right.svg';
import cx from 'classnames';

export default function FitSelectionRow({
  setCurrentScreen,
  panelScreen,
  selectedFit,
  isFitLocked
}) {
  const arrowClasses = cx(
    'fc-option-dropdown-arrow-collapsed',
    !selectedFit && 'fc-reduced-opacity'
  );
  const handleClick = () => {
    if (!isFitLocked) {
      setCurrentScreen(panelScreen);
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }, 100);
    }
  };

  return (
    <div className="row no-gutters fc-option" onClick={handleClick}>
      <div className="col fc-option-title">Fit</div>
      {selectedFit ? (
        <div className="col fc-option-selection-title">
          {selectedFit.answer}
        </div>
      ) : (
        <div className="col fc-option-empty-selection-title">
          Make a selection
        </div>
      )}
      <div className="col-1 fc-option-dropdown-arrow">
        <div className={arrowClasses}>
          <img src={ArrowRight} alt="Arrow pointing right" />
        </div>
      </div>
    </div>
  );
}
