import React from 'react';
import ModelImagePreview from './ModelImagePreview';

export default function FitImagePreview({
  productKey,
  selectedFit,
  remoteImagesPath,
  brandCode,
  showCustomPreviewImage
}) {
  return selectedFit && selectedFit.image ? (
    <div className="fc-preview-container fc-fit-preview-container">
      <img
        src={selectedFit.image}
        alt={'Fit preview'}
        className="fc-preview-image"
      />
    </div>
  ) : (
    <ModelImagePreview
      productKey={productKey}
      selectedFit={selectedFit}
      remoteImagesPath={remoteImagesPath}
      brandCode={brandCode}
      showCustomPreviewImage={showCustomPreviewImage}
    />
  );
}
