import React from 'react';
import ArrowRight from 'images/arrow-right.svg';
import cx from 'classnames';

export default function CustomAttributeRow({
  attributeName,
  selectedAttribute,
  setCurrentScreen,
  panelScreen,
  canOpen
}) {
  const arrowClasses = cx(
    'fc-option-dropdown-arrow-collapsed',
    !canOpen && 'fc-reduced-opacity'
  );
  const handleClick = () => {
    if (canOpen) {
      setCurrentScreen(panelScreen);
    }
  };

  return (
    <div className="row no-gutters fc-option" onClick={handleClick}>
      <div className="col fc-option-title">{attributeName}</div>
      {selectedAttribute ? (
        <div className="col fc-option-selection-title">
          {selectedAttribute.name}
        </div>
      ) : (
        <div className="col fc-option-empty-selection-title">
          Make a selection
        </div>
      )}
      <div className="col-1 fc-option-dropdown-arrow">
        <div className={arrowClasses}>
          <img src={ArrowRight} alt="Arrow pointing right" />
        </div>
      </div>
    </div>
  );
}
