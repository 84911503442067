import React, {useMemo} from 'react';
import BackToConfigurator from './BackToConfigurator';
import NextStepButton from './NextStepButton';
import FabricSelectedIcon from 'images/fabric-selected-check.svg';
import DefaultAttributeImage from 'images/PhotoComingSoon.png';
import cx from 'classnames';
import useImageLoader from "../hooks/useImageLoader";
import classNames from "classnames";
import SearchIcon from 'images/search.svg';

export default function AttributeSelectionPanel({
  productName,
  attributeName,
  options,
  selectedAttribute,
  setSelectedAttribute,
  setCurrentScreen,
  currencySymbol,
  nextAttribute,
  nextAttributeName,
  previousAttribute,
  canAddToBasket
}) {
  const [touched, setTouched] = React.useState(false);
  const [query, setQuery] = React.useState('');

  const attributeTitle =
    attributeName.charAt(0).toUpperCase() + attributeName.slice(1);

  const filteredOptions = useMemo(() => {
    if (query === '') {
      return options;
    } else {
      return filterByName(options, query)
    }
  }, [options, query]);

  function filterByName(arr, searchText) {
    return arr.filter(item => item.name.toLowerCase().includes(searchText.toLowerCase()));
  }

  return (
    <div className="fc-selection-panel fc-custom-selection-panel">
      <div className="d-flex flex-column">
        <div className="fc-selection-panel-content">
          <div className="row fc-back-to-configurator-row">
            <BackToConfigurator
              setCurrentScreen={setCurrentScreen}
              currencySymbol={currencySymbol}
            />
          </div>
          <div className="row fc-selection-panel-options fc-custom-selection-panel-options">
            <div className="fc-selection-panel-title-row searchable border-bottom-0">
              <div className="col-12 col-xl-6 fc-selection-panel-title fabric-panel-title">
                Select a {attributeTitle}
              </div>
              <div
                className={classNames(
                  'col-12 col-xl-6 mt-2 mt-xl-0 fc-fabrics-filter',
                  {
                    touched
                  }
                )}
              >
                <input
                  type="search"
                  placeholder="Search"
                  className="search-click"
                  id="fabric-search"
                  onChange={(event) => setQuery(event.target.value)}
                  value={query}
                />
                <label htmlFor="fabric-search" onClick={() => setTouched(true)}>
                  <img src={SearchIcon} alt="Search" />
                </label>
              </div>
            </div>
          </div>
          <div className="row fc-selection-pallet fc-custom-selection-pallet d-flex align-items-start">
            <div className="row no-gutters fc-fabric-pallet-row w-100">
              {options &&
                filteredOptions.map((attribute) => {
                  return (
                    <AttributeSelection
                      key={attribute.id}
                      attribute={attribute}
                      isSelected={attribute === selectedAttribute}
                      selectedAttribute={selectedAttribute}
                      setSelectedAttribute={setSelectedAttribute}
                    />
                  );
                })}
              {filteredOptions?.length < 1 && (
                <div
                  style={{fontSize: '20px'}}
                  className="w-100 d-flex justify-content-center mt-4"
                >
                  No {attributeTitle}s found
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="d-none d-md-block">
          <NextStepButton
            nextAttribute={nextAttribute}
            nextAttributeName={nextAttributeName}
            previousAttribute={previousAttribute}
            isDisabled={!selectedAttribute}
            setCurrentScreen={setCurrentScreen}
            productName={productName}
            canAddToBasket={canAddToBasket}
          />
        </div>
      </div>
    </div>
  );
}

export function AttributeSelection({
  attribute,
  isSelected,
  selectedAttribute,
  setSelectedAttribute
}) {
  const [isActive, setIsActive] = React.useState(null);
  const containerClasses = cx(
    'row no-gutters fc-custom-selection',
    (isActive || isSelected) && 'fc-custom-selection-active'
  );
  const loadedImageUrl = useImageLoader(attribute.images.display, DefaultAttributeImage);

  const handleClick = () => {
    if (selectedAttribute != attribute) {
      setSelectedAttribute(attribute);
    }
  };

  return (
    <div
      className={containerClasses}
      onMouseLeave={() => setIsActive(false)}
      onMouseEnter={() => setIsActive(true)}
      onClick={handleClick}
    >
      <div className="col fc-selection-sketch">
        <img
          src={loadedImageUrl}
          className="fc-sketch-image"
          alt={attribute.name}
        />
      </div>

      <div className="col fc-selection-title">
        <div className="">{attribute.name.toLowerCase()}</div>
      </div>
      <span className="fc-selected-sign">
        {isSelected && <img src={FabricSelectedIcon} alt="Selected" />}
      </span>
    </div>
  );
}
