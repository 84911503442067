import React from 'react';
import ArrowRight from 'images/arrow-right.svg';
import cx from 'classnames';

export default function MonogramSelectionRow({
  setCurrentScreen,
  canOpen,
  monogramSelections,
  monogramInitials,
  monogramFullySelected
}) {
  const classNames = cx(
    'fc-option-dropdown-arrow-collapsed',
    !canOpen && 'fc-reduced-opacity'
  );
  const handleClick = () => {
    if (canOpen) {
      setCurrentScreen('monogram');
    }
  };

  const selections = Object.keys(monogramSelections).filter(
    (key) => key !== 'monogram_initials'
  );

  return (
    <div className="row no-gutters fc-option" onClick={handleClick}>
      <div className="col fc-option-title">Monogram</div>
      {monogramFullySelected && !monogramSelections.monogram_initials ?
        <div className="col fc-option-selection-title">No monogram</div>
      : monogramFullySelected ?
        <div className="col fc-option-selection-title">
          {[
            monogramInitials,
            ...selections.map((s) => monogramSelections[s].name)
          ].join(', ')}
        </div>
      : <div className="col fc-option-empty-selection-title">
          Make a selection
        </div>
      }
      <div className="col-1 fc-option-dropdown-arrow">
        <div className={classNames}>
          <img src={ArrowRight} alt="Arrow pointing right" />
        </div>
      </div>
    </div>
  );
}
