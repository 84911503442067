import React from 'react';
import ArrowRight from 'images/arrow-right.svg';
import cx from 'classnames';
import { CONTRAST_STITCHING_CATEGORIES_ARRAY } from '../constants/contrast_stitching';
import { hasProp } from '../utils/object';

export default function ContrastStitchingRow({
  setCurrentScreen,
  canOpen,
  contrastStitchingEnabled,
  userSelections
}) {
  const classNames = cx(
    'fc-option-dropdown-arrow-collapsed',
    !canOpen && 'fc-reduced-opacity'
  );
  const handleClick = () => {
    if (canOpen) {
      setCurrentScreen('contrast_stitching');
    }
  };

  const selectionMade = CONTRAST_STITCHING_CATEGORIES_ARRAY.every((category) =>
    hasProp(userSelections, category) ? userSelections[category] !== null : true
  );

  return (
    <div className="row no-gutters fc-option" onClick={handleClick}>
      <div className="col fc-option-title">Contrast Stitching</div>
      {!contrastStitchingEnabled && selectionMade ?
        <div className="col fc-option-selection-title">
          No contrast stitching
        </div>
      : contrastStitchingEnabled && selectionMade ?
        <div className="col fc-option-selection-title">
          {[
            ...CONTRAST_STITCHING_CATEGORIES_ARRAY.map(
              (c) => userSelections[c].name
            )
          ].join(', ')}
        </div>
      : <div className="col fc-option-empty-selection-title">
          Make a selection
        </div>
      }
      <div className="col-1 fc-option-dropdown-arrow">
        <div className={classNames}>
          <img src={ArrowRight} alt="Arrow pointing right" />
        </div>
      </div>
    </div>
  );
}
